<template>
  <div>
    <v-container class="py-0">
      <transition name="slide-left-fade" mode="out-in" appear>
        <div>
          <v-card outlined class="pl-0 mb-5 tertiary" >
            <v-card-title>
              Enable open banking
              <v-spacer></v-spacer>
              <PageTitle class="mb-3 ">Open banking</PageTitle>
            </v-card-title>
            <v-card-text>
              <ol>
                <li>          
                  <span>Connect to your bank by clicking 
                    <v-btn depressed x-small class="mx-2" color="success">
                      <span class="font-weight-light text-capitalize">Authorize</span>
                    </v-btn>
                    in the table below.
                  </span>
              </li>
              <li>
                <span>Once the bank wizard completes successfully, link your account to open banking by clicking
                  <v-btn depressed x-small class="mx-2" color="blue">
                    <span class="font-weight-light white--text text-capitalize">Linkable accounts</span>
                  </v-btn>
                  in the table below.
                </span>
                
                </li>
              </ol>

              <v-divider class="my-5"></v-divider>

              <div class="d-flex align-center">
                <v-icon small class="mr-3" color="red lighten-3">mdi-alert</v-icon>
                <p class="ma-0 font-weight-light">agentOS is acting as an agent of TrueLayer, who is providing the 
                  regulated Account Information Service, <br/> and who is authorised by the FCA as an authorised Payment Institutions (ref. num.: 793171) 
                </p>
              </div>

            </v-card-text>
          </v-card>
        </div>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <OpenBanking v-if="bankAccounts" />
          <Skeleton v-else />
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import OpenBanking from '@/components/OpenBanking/OpenBankingTable'
import Skeleton from '@/components/SkeletonLoaders/SkeletonLoaderTable'
import {mapState} from 'vuex'
import PageTitle from '@/components/Slots/PageTitle.vue'

export default {
  name: 'openBankingPage',
  components: {
    OpenBanking,
    Skeleton,
    PageTitle
  },
  created() {
    this.$store.dispatch('fetchOpenBankingData')
  },
  computed: {
    ...mapState({
      bankAccounts: state => state.OpenBankingRequests.bankAccounts
    })
  }
}
</script>

<style>

</style>