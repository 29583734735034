<template>
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div class="d-flex align-center">
            <v-icon large left color="white">mdi-bank</v-icon>
            <p class="subtitle-1 my-0" v-if="bankAccounts.Count === 1">{{ bankAccounts.Count }} bank account</p>
            <p class="subtitle-1 my-0" v-else>{{ bankAccounts.Count }} bank accounts</p>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}" />
      </v-card-title>  

      <v-data-table
        class="tableColor"
        :headers="headers"
        :items="bankAccounts.Data"
        :search="search"
        :loading="loading"
      >

       <template v-slot:item.Authorize="{ item }">
         <v-btn 
           @click="authorizeAccount(item)"
           color="green lighten-1" 
           depressed
           small 
          >
           <span class="text-capitalize white--text font-weight-light">Authorise</span>
         </v-btn>
       </template>

        <template v-slot:item.LinkAccount="{ item }">
         <v-btn 
          :disabled="!item.IsOpenBankingEnabled" 
          @click="linkableAccounts(item)"
          color="blue lighten-1" 
          depressed
          small 
        >
           <span class="white--text font-weight-light text-capitalize">Linkable accounts</span>
         </v-btn>
       </template>

        <template v-slot:item.IsOpenBankingEnabled="{ item }">
          <v-icon color="red" v-if="!item.IsOpenBankingEnabled">mdi-close-outline</v-icon>
          <v-icon color="green" v-else>mdi-check-outline</v-icon>
        </template>

        <template v-slot:item.UnlinkAccount="{ item }">
          <v-btn small outlined color="grey" @click="unlinkAccount(item)">
            <span class="font-weight-light text-capitalize">Unlink</span>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable  
      persistent :overlay="false"
      max-width="700px"
      transition="dialog-transition"
    >
     <v-card>
       <v-card-title class="primary white--text">
          <span class="headline font-weight-light">Link accounts</span>
         <v-spacer></v-spacer>
         <v-btn @click="closeDialog()" text color="white">
           <v-icon right>mdi-close</v-icon>
         </v-btn>
       </v-card-title>
       <v-card-text class="py-3">
         <ul>
           <li>
             <span class="font-weight-medium">Select an open banking account to link, then click Link.</span>
           </li>
         </ul>
         <v-row>
           <v-col cols="6">
             <v-card outlined>
               <v-card-title class="font-weight-light">
                 System account
               </v-card-title>
               <v-divider></v-divider>
               <v-card-text class="pa-3">
                {{ systemBancAccountName }}
                <p>{{ systemBancAccountNumber }}</p>  
               </v-card-text>
             </v-card>
           </v-col>
           <v-col cols="6">
             <v-card :loading="loadingLinkAccount" outlined>
               <v-card-title class="font-weight-light">
                 Open banking account(s)
               </v-card-title>
               <v-divider></v-divider>
               <v-card-text class="pa-3">
                 <!-- <div v-for="account in openBankingAccounts" :key="account.AccountID">
                   {{account.AccountDisplayName}}
                 </div> -->
                 <v-radio-group v-model="selectedOpenBankingAccount">
                  <v-radio
                    color="secondary"
                    active-class="caption green--text"
                    v-for="account in openBankingAccounts"
                    :key="account.AccountID"
                    :label="account.AccountDisplayName"
                    :value="account.AccountID"
                  ></v-radio>
                </v-radio-group>
               </v-card-text>
             </v-card>
           </v-col>
         </v-row>
       </v-card-text>
       <v-card-actions class="pa-5">
         <v-btn color="red" outlined @click="closeDialog()">close</v-btn>
         <v-spacer></v-spacer>
         <v-btn @click="linkAccounts" :disabled="!selectedOpenBankingAccount" width="150px" class="white--text text-capitalize" color="green">Link</v-btn>
       </v-card-actions>
     </v-card> 
    </v-dialog>

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import TableSearch from '@/components/TableComponents/TableSearch.vue'

export default {
  components: {
    Snackbar,
    TableSearch
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      loadingLinkAccount: false,
      dialog: false,
      systemBancAccountID: '',
      systemBancAccountNumber: '',
      systemBancAccountName: '',
      openBankingAccountID: '',
      openBankingAccountName: '',
      openBankingAccounts: '',
      selectedOpenBankingAccount: '',
      search: '',
      headers: [
        {
          text: 'Account',
          align: 'left',
          value: 'AccountName',
        },
        { text: '', value: 'AccountNumber' },
        { text: '', value: 'OpenBankingRenewalLimit' },
        { text: 'OB Enabled', value: 'IsOpenBankingEnabled' },
        { text: 'Authorize', value: 'Authorize' },
        { text: 'Link', value: 'LinkAccount' },
        { text: 'Unlink', value: 'UnlinkAccount' },
      ]
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false,
      this.systemBancAccountID = ''
      this.systemBancAccountName = ''
      this.systemBancAccountNumber = ''
      this.selectedOpenBankingAccount = ''
      this.openBankingAccounts = ''
    },
    authorizeAccount(item) {
      this.$store.dispatch('OpenBankingRequests/authorizeAccount', item.AccountID)
      .then( ({data}) => {
        console.log(data)
        window.open(data, '_blank');
      })

    },
    linkableAccounts(item) {
      this.loading = true
      this.$store.dispatch('OpenBankingRequests/linkableAccount', item.AccountID)
      .then(({data}) => {
        console.log(data)
        this.systemBancAccountID = item.AccountID
        this.systemBancAccountName = item.AccountName
        this.systemBancAccountNumber = item.AccountNumber
        this.openBankingAccounts = data.Data
        this.dialog = true
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    linkAccounts() {
      this.loadingLinkAccount = true
      this.$store.dispatch('OpenBankingRequests/linkAccount', {
        systemBancAccountID: this.systemBancAccountID,
        openBankingAccountID: this.selectedOpenBankingAccount
      })
      .then(res => {
        this.loadingLinkAccount = false
        console.log(res)
        this.dialog = false
        this.snackbarText = 'Accounts linked.'
        this.snackbarColor = 'green'
        this.snackbar = true
      })
      .catch(err => {
        this.loadingLinkAccount = false
        console.log(err)
        this.dialog = false
        this.snackbarText = 'Error whilst linking accounts.'
        this.snackbarColor = 'red'
        this.snackbar = true
      })
    },
    unlinkAccount(item) {
      this.$store.dispatch('OpenBankingRequests/unlinkAccount', item.AccountID)
      .then(res => {
        this.loadingLinkAccount = false
        console.log(res)
        this.dialog = false
        this.snackbarText = 'Accounts unlinked.'
        this.snackbarColor = 'green'
        this.snackbar = true
      })
      .catch(err => {
        this.loadingLinkAccount = false
        console.log(err)
        this.dialog = false
        this.snackbarText = 'Error whilst unlinking accounts.'
        this.snackbarColor = 'red'
        this.snackbar = true
      })
    },
    closeSnackbar() {
      this.snackbar = false
      this.snackbarText = '' 
      this.snackbarColor = ''
    },
  },
  computed: {
    ...mapState({
      bankAccounts: state => state.OpenBankingRequests.bankAccounts
    }),

  },
  created() {
    // this.$store.dispatch('OpenBankingRequests/getBankAccounts')
  }
}
</script>

<style>
  .v-label {
    font-size: 14px;
    color: red;
  }
</style>